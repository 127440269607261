<template>
	<section class="fullpage-partner fp-partner">
		<div class="fp-content container">
			<div class="fp-partner-scroll fp-normal-scroll">
				<div class="fp-partner-scroll__video" ref="video">
					<video muted id="partnerVideoPC" class="pc">
						<source src="~@/assets/img/renew/partner_pc1.webm" type="video/webm" />
						<source src="~@/assets/img/renew/partner_pc1.mp4" type="video/mp4" />
					</video>
					<video muted id="partnerVideoMO" class="mo">
						<source src="~@/assets/img/renew/partner_mo1.webm" type="video/webm" />
						<source src="~@/assets/img/renew/partner_mo1.mp4" type="video/mp4" />
					</video>
				</div>
				<div class="fp-partner-scroll__inner">
					<div class="fp-partner-scroll__img-handle">
						<div class="fp-partner-scroll__img">
							<img class="pc" src="~@/assets/img/renew/partner_pc1.png" alt="온라인 진출 파트너 목록" />
							<img class="mo" src="~@/assets/img/renew/partner_mo1.png" alt="온라인 진출 파트너 목록" />
						</div>
					</div>
				</div>
				<div class="fp-partner-scroll__bar">
					<input type="range" min="0" max="100" value="0" class="slider" id="imageRange" @change="scrollVertically" />
				</div>
			</div>
		</div>
	</section>
</template>
<script>
// import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';
export default {
	name: 'OnfanPartner',
	props: {},
	components: {},
	computed: {},
	data: () => ({
		isVisible: false, // 리소스가 로드 되면 함수 실행을 멈출지 말지 정하는 변수
	}),
	created() {},
	mounted() {},
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
			document.querySelector('.fp-partner-scroll__inner').addEventListener('scroll', this.onPartnerScroll);
		});
	},
	beforeDestroy() {
		//window 의 이벤트는 해제가 필요하지만 document는 이미 element가 제거된 상태라 이벤트 해제 에러가 발생하는듯 함. 주석처리.
		//document.querySelector('.fp-partner-scroll__inner').removeEventListener('scroll', this.onPartnerScroll);
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver((entries, observer) => {
				entries.forEach(async (entry) => {
					// 감지대상이 교차영역에 진입 할 경우
					if (entry.isIntersecting) {
						document.querySelector('html').classList.add('__fullpage-partner');
						this.isVisible = true;
						this.toggleVideoPlay();
						document.getElementById('imageRange').value = 0;
						setTimeout(() => {
							document.querySelector('.fp-partner-scroll__inner').scrollTo(0, 0);
						}, 1000);
					} else {
						document.querySelector('html').classList.remove('__fullpage-partner');
						this.isVisible = false;
						this.toggleVideoPause();
					}
				});
			});
			io.observe(this.$refs.video);
		},

		// 파트너 영역의 스크롤
		onPartnerScroll(e) {
			const scrollHeight = e.target.scrollHeight;
			const scrollTop = e.target.scrollTop;
			const clientHeight = e.target.clientHeight;
			let scrollValue = parseInt((scrollTop / (scrollHeight - clientHeight)) * 100);
			document.getElementById('imageRange').value = scrollValue;
		},
		// 가로 스크롤 슬라이더 컨트롤
		scrollVertically() {
			const value = document.getElementById('imageRange').value;
			const scrollHeight = document.querySelector('.fp-partner-scroll__inner').scrollHeight;
			const clientHeight = document.querySelector('.fp-partner-scroll__inner').clientHeight;

			let scrollTo = parseInt((scrollHeight - clientHeight) * (value / 100));
			document.querySelector('.fp-partner-scroll__inner').scrollTo(0, scrollTo);
		},

		checkVideoPlaying() {
			const videoPc = document.getElementById('partnerVideoPC');
			// const videoMo = document.getElementById('partnerVideoMO');
			return videoPc.currentTime > 0 && !videoPc.paused && !videoPc.ended && videoPc.readyState > videoPc.HAVE_CURRENT_DATA;
		},
		// 비디오 재생
		toggleVideoPlay() {
			const videoPc = document.getElementById('partnerVideoPC');
			const videoMo = document.getElementById('partnerVideoMO');
			try {
				if (!this.checkVideoPlaying()) {
					// setTimeout(() => {
					videoPc.currentTime = 0;
					videoPc.play();
					videoMo.currentTime = 0;
					videoMo.play();
					// }, 1000);
				}
			} catch (error) {
				console.error(error);
			}
		},
		// 비디오 중지
		toggleVideoPause() {
			const videoPc = document.getElementById('partnerVideoPC');
			const videoMo = document.getElementById('partnerVideoMO');
			try {
				videoPc.pause();
				videoMo.pause();
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>
